import { createGlobalStyle } from "styled-components";
import montreal from "./fonts/NeueMontreal-Regular.otf";
import montrealB from "./fonts/NeueMontreal-Bold.otf";
import montrealL from "./fonts/NeueMontreal-Light.otf";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');

@font-face {
  font-family: montreal;
  src: url(${montreal});
}

@font-face {
  font-family: montreal-b;
  src: url(${montrealB});
}

@font-face {
  font-family: montreal-l;
  src: url(${montrealL});
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}


  body {
    margin: 0;
    padding: 0;

    
  }

  #root {
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

  }
  html {
    overflow-y: overlay;
}
`;

export default GlobalStyle;
