import bg from "./assets/images/bg.jpg";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./assets/globalStyles";
import { API, theme } from "./assets/Variables";
import { Home } from "./pages/Home";
import { Footer } from "./sections/app/Footer";
import { Register } from "./pages/Register";
import { Nav } from "./sections/app/Nav";
import { Success } from "./pages/Success";
import { Paypal } from "./pages/Paypal";
import { useEffect, useState } from "react";
import { User } from "./types";
import { PaymentForm } from "react-square-web-payments-sdk";
import { Message } from "./components/Message";
import { About } from "./pages/About";
import { UserHome } from "./pages/UserHome";
import axios from "axios";
import { Course } from "./pages/Course";
import { Courses } from "./pages/Courses";
import moment from "moment";

const publicPaths = ["/", "/inscribirse", "/equipo", "/pago"];

function App() {
  const [user, setUser] = useState<User>({
    first: "",
    last: "",
    email: "",
    token: "",
    joined: "",
    emailVerified: false,
    subscribed: false,
    validUntil: null,
  });

  const [message, setMessage] = useState<null | string>(null);

  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("USER UPDATED: ", user);
    //check payment status
    if (user?.jwt) {
      if (user?.validUntil === null) {
        navigate(`/pago`);
      } else if (user?.validUntil?.length) {
        const expires = moment(user?.validUntil);
        const today = moment();
        if (expires.isAfter(today)) {
          navigate(`/user-home/${user.username}`);
        } else {
          navigate(`/pago`);
        }
      }
    }

    // if (location.pathname === "/")
    //   user?.jwt && navigate(`/user-home/${user.username}`);
  }, [user]);

  // block pages that need payment and redirect to payment

  useEffect(() => {
    console.log("LOCATION: ", location.pathname);
    window.scrollTo(0, 0);

    if (!publicPaths.includes(location.pathname)) {
      //protected path - check if valid
      if (user?.jwt) {
        if (user?.validUntil === null) {
          navigate(`/pago`);
        } else if (user?.validUntil?.length) {
          const expires = moment(user?.validUntil);
          const today = moment();
          if (expires.isAfter(today)) {
            // navigate(`/user-home/${user.username}`);
          } else {
            navigate(`/pago`);
          }
        }
      } else {
        navigate(`/`);
      }
    }
  }, [location]);

  useEffect(() => {
    //auto-login if valid jwt
    const jwt = localStorage.getItem("jwt");
    if (jwt) {
      axios({
        method: "GET",
        url: "https://api.pentecostal.pro/api/users/me?populate=*",
        headers: {
          Authorization: "Bearer " + jwt,
        },
      })
        .then(({ data }) => {
          console.log("sweet: ", data);
          setUser({ ...data, jwt: jwt });
        })
        .catch((error) => {
          localStorage.clear();
          setUser({
            first: "",
            last: "",
            email: "",
            token: "",
            joined: "",
            emailVerified: false,
            subscribed: false,
          });
          navigate("/");
        });
    } else {
      if (
        location.pathname !== "/equipo" &&
        location.pathname !== "/inscribirse"
      ) {
        navigate("/");
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BG />
      <AppElement className="App">
        {message && <Message message={message} setMessage={setMessage} />}
        <Nav user={user} setUser={setUser} />
        <GlobalStyle />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                user={user}
                setUser={setUser}
                message={message}
                setMessage={setMessage}
              />
            }
          />
          <Route
            path="/inscribirse"
            element={
              <Register
                user={user}
                setUser={setUser}
                message={message}
                setMessage={setMessage}
              />
            }
          />
          <Route
            path="/user-home/:username"
            element={
              <UserHome
                user={user}
                setUser={setUser}
                message={message}
                setMessage={setMessage}
              />
            }
          />
          <Route
            path="/course/:id"
            element={
              <Course
                user={user}
                setUser={setUser}
                message={message}
                setMessage={setMessage}
              />
            }
          />
          <Route
            path="/courses"
            element={
              <Courses
                user={user}
                // setMessage={setMessage}
              />
            }
          />
          <Route path="/success" element={<Success />} />
          <Route
            path="/pago"
            element={<Paypal user={user} setUser={setUser} />}
          />
          <Route path="/equipo" element={<About />} />
        </Routes>
        <Footer />
      </AppElement>
      {/* </PaymentForm> */}
    </ThemeProvider>
  );
}

export default App;

const AppElement = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: #000000a2;
`;

const BG = styled.div`
  width: 100%;
  background-image: url("https://images.unsplash.com/photo-1499652848871-1527a310b13a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80");
  background-size: cover;
  filter: contrast(1.3);

  position: fixed;
  height: 100vh;
  z-index: -1;
`;
