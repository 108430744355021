import React from "react";
import styled from "styled-components";
import { text, theme } from "../assets/Variables";

export const Text: React.FC<{
  h1?: boolean;
  h2?: boolean;
  h3?: boolean;
  children?: string;
  color?: string;
}> = ({ h1, h2, h3, children, color = theme.color.grey }) => {
  switch (true) {
    case h1:
      return <H1Element color={color}>{children}</H1Element>;

    case h2:
      return <H2Element color={color}>{children}</H2Element>;

    case h3:
      return <H3Element color={color}>{children}</H3Element>;

    default:
      return <PElement color={color}>{children}</PElement>;
  }
};

const H1Element = styled.h1`
  font-size: ${text.h1.fontSize};
  font-family: ${text.h1.font};
  color: ${(props) => props.color};
  margin: ${(props) => props.theme.measurements.marginS};
  font-weight: ${text.h1.fontWeight};
  @media (max-width: 768px) {
    font-size: 64px;
  }
`;

const H2Element = styled.h2`
  font-family: ${text.h2.font};
  font-size: ${text.h2.fontSize};
  color: ${(props) => props.color};
  margin: ${(props) => props.theme.measurements.marginS};
  font-weight: ${text.h1.fontWeight};
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 100%;
  }
`;

const H3Element = styled.h3`
  font-family: ${text.h3.font};
  font-size: ${text.h3.fontSize};
  color: ${(props) => props.color};
  margin: ${(props) => props.theme.measurements.marginS};
  font-weight: ${text.h3.fontWeight};
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const PElement = styled.p`
  font-family: ${text.p.font};
  font-size: ${text.p.fontSize};
  color: ${(props) => props.color};
  margin: ${(props) => props.theme.measurements.marginS};
  font-weight: ${text.p.fontWeight};
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
