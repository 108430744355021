import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Text } from "../components/Text";

export const Success: React.FC<{}> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timmy = setTimeout(() => {
      navigate("/");
    }, 6000);
    return () => clearTimeout(timmy);
  }, []);
  return (
    <Wrapper>
      <Text h1>Your account is now active. You may now login.</Text>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
