import axios from "axios";
import { darken, lighten } from "polished";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../assets/Variables";
import { Text } from "../components/Text";
import { User } from "../types";

export const Courses: React.FC<{ user: User }> = ({ user }) => {
  const [courses, setCourses] = useState([]);
  const getCourses = async () => {
    const { data } = await axios({
      method: "GET",
      url: "https://api.pentecostal.pro/api/courses?populate=*",
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
    });
    console.log(data.data);
    setCourses(data.data);
  };

  useEffect(() => {
    if (user.jwt) {
      getCourses();
    }
  }, [user]);

  return (
    <Wrapper>
      <Text h1 color={theme.color.white}>
        Cursos
      </Text>
      {courses &&
        courses.map((course: any) => (
          <Course key={course.id}>
            <Link to={`/course/${course.id}`}>
              <Text color="black">
                {`${course.attributes.teacher.data.attributes.name}`}
              </Text>
              <Text h2>{course.attributes.title}</Text>
              <Text h3>{"Comiensa: " + course.attributes.startDate}</Text>
            </Link>
          </Course>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;

  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  padding-top: 100px;
  gap: 4px;
`;
const Course = styled.div`
  display: flex;
  max-width: 432px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  ${theme.shadow}
  padding: 12px;

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
  }
  h2 {
    padding: 14px;
    color: ${theme.color.tertiary};
  }
  h3 {
    padding: 14px 14px;
    color: ${theme.color.white};
    background-color: #002545;
    border-radius: 45px;
    font-size: 16px;
    width: 200px;
  }
  p {
    padding-left: 14px;
    padding-bottom: 14px;
    color: ${theme.color.black};
  }

  @media (max-width: 768px) {
    width: initial;
    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 16px;
    }

    p {
      font-size: 20px;
    }
  }

  background-color: ${theme.color.secondary};
  transition: 300ms;
  :hover {
    transition: 300ms;
    background-color: ${lighten(0.9, theme.color.primary)};
    h2 {
      color: ${theme.color.primary};
    }
    h3 {
      color: ${theme.color.primary};
    }
    p {
      padding-left: 14px;
      padding-bottom: 14px;
      color: ${darken(0.4, theme.color.tertiary)};
    }
  }
`;
