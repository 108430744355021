export const theme = {
  shadow: `box-shadow: 3px 3px 10px 5px #4a4a4a52;`,
  color: {
    primary: "#005975",
    secondary: "rgb(73, 135, 136)",
    tertiary: "#f3f5f7",
    grey: "#6f738f",
    black: "rgb(59, 66, 69)",
    white: "white",
  },
  measurements: {
    radius: "4px",
    margin: "29px",
    marginS: "4px",
    transition: 0.2,
    padding: "29px",
    paddingL: "58px",
    pageMargin: "115px 58px",
  },
  button: {
    width: "250px",
    height: "60px",
  },
};

export const text = {
  fonts: {
    primary: "font-family: 'Source Sans Pro', sans-serif;",
    secondary: "montreal",
  },
  h1: {
    font: "'Source Sans Pro', sans-serif;",
    fontSize: "96px",
    fontWeight: 800,
  },
  h2: {
    font: "montreal",
    fontSize: "32px",
    fontWeight: 200,
  },
  h3: {
    font: "montreal",
    fontSize: "28px",
    fontWeight: 500,
  },
  h4: {
    font: "montreal",
    fontSize: "20px",
    fontWeight: 600,
  },
  p: {
    font: "montreal",
    fontSize: "32px",
    fontWeight: 100,
  },
};

export const API = {
  STRAPI: "https://api.pentecostal.pro/api/auth/local/",
};

export const PAYPAL_API = {
  account: {
    personal: {
      email: "sb-47vspj17157833@personal.example.com",
      password: "J,#0Y{tf",
    },
    business: {
      email: "sb-6o0k417157834@business.example.com",
      password: "sA*|MR_3",
    },
  },
  client_id:
    "AS-DZlotoyk64yKJDqkh4P340GZhFfF2wO1_k-4L2G50C2zTy8biuR-BztKbO3NjxqNgmbqFJ-APUBap",
  secret:
    "EMjRcQJPRscvi0__Y6f0DNwjusumRCDCjydUHZb6KbRUwnZy1T6pOqoqQvE4-va33IvYc3GgvJT_ngfu",
};
