import React, { useEffect, useState } from "react";
import Martin from "../assets/images/about/martin.jpg";
import Carmen from "../assets/images/about/carmen.jpg";
import Leonso from "../assets/images/about/ramirez.jpg";
import Soto from "../assets/images/about/soto.jpg";
import styled from "styled-components";
import { text, theme } from "../assets/Variables";
import { Text } from "../components/Text";
import { Position } from "../components/Position";
import axios from "axios";

export const About: React.FC<{}> = () => {
  const [teachers, setTeachers] = useState<any>([]);
  const [topTeachers, setTopTeachers] = useState<any>([]);
  const maestros = [
    {
      name: "Martin Enriquez",
      title: "Pastor",
      img: Martin,
    },
    {
      name: "Carmen Rojas",
      title: "Pastor",
      img: Carmen,
    },
    {
      name: "Miguel Rofriguez",
      title: "Pastor",
      img: Carmen,
    },
    {
      name: "Oscar Soto",
      title: "Pastor",
      img: Soto,
    },
    {
      name: "Leonso Ramirez",
      title: "Pastor",
      img: Leonso,
    },
    {
      name: "Carlos Rodriguez",
      title: "Pastor",
      img: Carmen,
    },
    {
      name: "Isidro Avila",
      title: "Pastor",
      img: Carmen,
    },
    {
      name: "Andres Hurtado",
      title: "Pastor",
      img: Carmen,
    },
  ];

  const getTeachers = async () => {
    const { data } = await axios({
      method: "GET",
      url: "https://api.pentecostal.pro/api/teachers?populate=*",
    });
    console.log(data.data);
    let tempTeachersNotTop: any = [];
    let tempTeachersOnTop: any = [];
    data.data.forEach((item: any) => {
      if (item.attributes.showOnTop) {
        tempTeachersOnTop.push(item);
      } else {
        tempTeachersNotTop.push(item);
      }
    });
    setTeachers(tempTeachersNotTop);
    setTopTeachers(tempTeachersOnTop);
  };

  useEffect(() => {
    getTeachers();
  }, []);
  return (
    <Wrapper>
      <Text h2 color={theme.color.white}>
        El Equipo de Liderazgo
      </Text>

      <Maestros>
        {topTeachers.map((maestro: any) => (
          <Leader
            key={maestro.id}
            img={
              "https://api.pentecostal.pro" +
              maestro.attributes.photo.data.attributes.url
            }
            name={maestro.attributes.name}
            title={maestro.attributes.title}
          />
        ))}
      </Maestros>
      <Text h2 color={theme.color.white}>
        Nuestros Maestros
      </Text>

      <Maestros>
        {teachers.map((maestro: any) => (
          <Maestro
            key={maestro.id}
            img={
              "https://api.pentecostal.pro" +
              maestro.attributes.photo.data.attributes.url
            }
            name={maestro.attributes.name}
            title={maestro.attributes.title}
          />
        ))}
      </Maestros>
    </Wrapper>
  );
};

const Maestro: React.FC<{ img: string; title: string; name: string }> = ({
  img,
  name,
  title,
}) => {
  return (
    <MaestroWrapper>
      <Pic src={img} />
      <Text h3>{name}</Text>
      <Title>{title}</Title>
    </MaestroWrapper>
  );
};

const Leader: React.FC<{ img: string; title: string; name: string }> = ({
  img,
  name,
  title,
}) => {
  return (
    <LeaderWrapper>
      <PicLeader src={img} />
      <Text h3>{name}</Text>
      <Title>{title}</Title>
    </LeaderWrapper>
  );
};

const Wrapper = styled.div`
  max-width: 100vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: ${theme.color.tertiary}; */
  padding-top: 150px;
  flex-grow: 1;
  text-align: center;
  /* background-image: linear-gradient(to bottom, #ffffff, #d9d9d9); */
`;

const Maestros = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  max-width: 900px;
  margin-top: 32px;
  margin-bottom: 64px;
`;

const MaestroWrapper = styled.div`
  width: 230px;
  overflow: hidden;
  background-color: #fffffff4;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${theme.measurements.marginS};
  border-radius: ${theme.measurements.radius};
  ${theme.shadow}
`;

const LeaderWrapper = styled.div`
  width: 230px;
  overflow: hidden;
  background-color: #fffffff4;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${theme.measurements.marginS};
  border-radius: ${theme.measurements.radius};
  ${theme.shadow}
`;

const PicLeader = styled.img`
  width: 100%;
  height: 216px;
  object-fit: cover;
  border-radius: 3px;
  margin-bottom: 14px;
`;

const Pic = styled.img`
  width: 100%;
  height: 216px;
  object-fit: cover;
  border-radius: 3px;
  margin-bottom: 14px;
`;

const Title = styled.h4`
  ${text.h3}
  color: ${theme.color.primary};
  margin: ${theme.measurements.marginS};
`;
