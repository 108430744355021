import { lighten, darken } from "polished";
import React from "react";
import styled from "styled-components";
import { text, theme } from "../assets/Variables";

export const Button: React.FC<{
  children: string;
  onClick?: Function;
  theme?: any;
  secondary?: boolean;
  tertiary?: boolean;
  darken?: boolean;
}> = ({
  children,
  onClick = () => console.warn("Add an onClick prop to Button"),
  theme,
  secondary = false,
  tertiary = false,
  darken = false,
}) => {
  return (
    <Btn
      secondary={secondary}
      variant={tertiary ? "tertiary" : secondary ? "secondary" : "primary"}
      darken={darken}
      onClick={() => onClick()}
    >
      {children}
    </Btn>
  );
};

const Btn = styled.button<{
  secondary: boolean;
  variant: string;
  darken: boolean;
  onClick: Function;
}>`
  text-transform: uppercase;
  width: ${(props) => props.theme.button.width};
  height: ${theme.button.height};
  font-size: ${text.h4.fontSize};
  font-weight: ${text.h4.fontWeight};
  border: none;
  border: 3px solid ${(props) => props.theme.color[props.variant]};
  background-color: ${(props) => props.theme.color[props.variant]};
  color: ${(props) =>
    props.darken ? darken(0.3, props.theme.color.tertiary) : "white"};
  border-radius: 50px;
  margin: ${(props) => props.theme.measurements.marginS};
  cursor: pointer;
  ${theme.shadow}
  transition: ${(props) => props.theme.measurements.transition}s
    background-color color;
  :hover {
    border-color: white;
    transition: ${(props) => props.theme.measurements.transition}s;
    background-color: ${(props) =>
      props.darken
        ? darken(0.3, props.theme.color[props.variant])
        : "#3535354a"};
    color: ${(props) =>
      props.darken
        ? lighten(0.1, props.theme.color.tertiary)
        : darken(0.1, props.theme.color.tertiary)};
  }
`;
