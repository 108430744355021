import React from "react";
import styled from "styled-components";
import { theme } from "../../assets/Variables";
import { Text } from "../../components/Text";

export const Footer = () => {
  return (
    <FooterEl>
      <Text h3>Copyright 2022</Text>

      <Text h3>
        Created by{" "}
        <a
          href="https://devshore.design"
          target={"_blank"}
          referrerPolicy="no-referrer"
          rel="noreferrer"
        >
          devshore.design
        </a>
      </Text>
    </FooterEl>
  );
};

const FooterEl = styled.div`
  position: relative;

  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background-color: ${theme.color.tertiary};
  @media (max-width: 768px) {
    height: initial;
    padding: 32px;
  }
  ${theme.shadow}
`;
