import { darken, lighten } from "polished";
import React from "react";
import styled from "styled-components";
import { theme } from "../../assets/Variables";
import { Text } from "../../components/Text";

const info = [
  {
    title: "nuestra vision",
    body: (
      <>
        Que siempre haya una generación pentecostal que conoce a su Dios. Por
        medio de la enseñanza de la Palabra de Dios, como testimonio a cada
        generación, para que cada generación sea fiel al Dios verdadero.
      </>
    ),
  },
  {
    title: "Nuestro Objetivo",
    body: (
      <>
        1. Instruir en la Palabra de Dios para que lleguen al conocimiento de la
        verdad.
        <br />
        2. Formar: Con el fin de que el hombre de Dios crezca en fe y sea fiel a
        Dios.
        <br />
        3. Enviar: A compartir con su generación, y la venidera el conocimiento
        del único Dios verdadero.
      </>
    ),
  },
  {
    title: "Detalles",
    body: (
      <>
        Lugar: 46 E Market St, Long Beach, CA 90805
        <br />
        Horario: sábados 3PM a 6 PM
        <br />
        Duración del instituto: dos años.
        <br />
        Registración: $20.00 <br />
        Mensualidad: $40.00 <br />
        Material de estudio: PDF, gratis. <br />
        Impreso: $ 40.00 <br />
        Ceremonia de inauguración: 20 de Agosto 3PM
      </>
    ),
  },
  {
    title: "1mer semestre",
    body: (
      <>
        Pastor Leonzo Ramirez - Hechos 1
        <br />
        Pastor Oscar Soto - La vida de Cristo Tomo 1
        <br />
        Pastor Miguel Rodriguez - Doctrina Tomo 1
        <br />
        Pastor Carlos Rodriguez - Introducción a la biblia
        <br />
      </>
    ),
  },
  {
    title: "2do Semestre",
    body: (
      <>
        Pastor Leonzo Ramirez - Hechos 2
        <br />
        Pastor Oscar Soto - La vida de Cristo Tomo 2
        <br />
        Pastor Miguel Rodriguez - Doctrina tomo 2
        <br />
        Pastor Carlos Rodriguez - Hermenéutica
        <br />
      </>
    ),
  },
];

export const InfoHome = () => {
  return (
    <>
      {info.map((el, i) => (
        <Info key={i} title={el.title} body={el.body} />
      ))}
    </>
  );
};

const Info = ({ title, body }) => {
  return (
    <InfoBox>
      <Text h2>{title}</Text>
      <Text>{body}</Text>
    </InfoBox>
  );
};

const InfoBox = styled.div`
  width: 100%;
  background-color: ${theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    width: 80%;
    text-align: right;
    text-transform: capitalize;
    padding: ${theme.measurements.padding};
    color: ${theme.color.white};
  }
  p {
    width: 80%;
    padding: ${theme.measurements.padding};
    padding-top: 0;
    color: ${theme.color.tertiary};
  }
  :nth-child(even) {
    background-color: ${theme.color.tertiary};
    h2,
    p {
      color: ${theme.color.grey};
    }
  }

  @media (max-width: 768px) {
  }
`;
