import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../assets/Variables";
import { Button } from "../components/Button";
import { Text } from "../components/Text";
import { User } from "../types";
import { ToCapitalize } from "../utils";

export const UserHome: React.FC<{
  user: User;
  setUser: Function;
  message: null | string;
  setMessage: Function;
}> = ({ user, setUser, message, setMessage }) => {
  if (user.jwt) {
    return (
      <Wrapper>
        <Text h2 color="secondary">{`Bienvendios,\n ${ToCapitalize(
          user.firstname
        )} ${ToCapitalize(user.lastname)}`}</Text>
        {/* No hay cursos todavia. */}
        <Link to={"/courses"}>
          <Button>Cursos</Button>
        </Link>
        <SquareButton />
      </Wrapper>
    );
  } else {
    return <Wrapper></Wrapper>;
  }
};

const Wrapper = styled.div`
  /* width: 100%; */
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${theme.measurements.pageMargin};
  background-color: ${theme.color.tertiary};
  flex-direction: column;
  gap: 16px;
  padding-top: 40%;
  h2 {
    /* margin-right: auto; */
    text-align: center;
    font-size: 24px;
  }
`;

const SquareButton = () => {
  return (
    <a
      target="_blank"
      href="https://square.link/u/67icLemJ?src=embed"
      rel="noreferrer"
    >
      <Button secondary>Ofrendas</Button>
    </a>
  );
};
