import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { API, theme } from "../../assets/Variables";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Position } from "../../components/Position";
import { Text } from "../../components/Text";
import { handleError } from "../../utils";
import Logo from "../../assets/images/logo.png";
import gsap from "gsap";

export const HomeHero: React.FC<{
  setMessage: Function,
  setUser: Function,
}> = ({ setMessage, setUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const logoRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({});
    if (logoRef.current) {
      tl.to(logoRef.current, {
        rotate: 360,
        scrollTrigger: {
          trigger: document.body,
          scrub: true,
          start: "top top",
          end: "bottom bottom",
        },
      });
    }
    return () => {
      tl.kill();
    };
  }, []);

  const login = async () => {
    try {
      const { data } = await axios({
        method: "POST",
        url: API.STRAPI,
        data: {
          identifier: email,
          password: password,
        },
      });

      //if succesful, there should be jwt
      if (data.jwt && data.user?.email) {
        localStorage.setItem("jwt", data.jwt);
        localStorage.setItem("identifier", data.user.email);
        setUser({ ...data.user, jwt: data.jwt });
      } else {
        return setMessage("There was an issue. Try again later.");
      }
      console.log(data);
      if (data?.success) {
        setMessage(data?.message);
      } else {
        // setMessage(resp.data.message);
      }
    } catch (error) {
      setMessage(handleError(error));
    }
  };
  return (
    <Hero>
      <TitleBox>
        <LogoWrapper src={Logo} ref={logoRef} />
        <Text h1 color={theme.color.white}>
          IBGP
        </Text>
        <Text color={theme.color.white} h2>
          Instituto Bíblico Generación Pentecostal
        </Text>
      </TitleBox>

      <LoginSection>
        <Input
          value={email}
          setValue={setEmail}
          placeholder="Email"
          type="email"
        />
        <Input
          value={password}
          setValue={setPassword}
          placeholder="Password"
          type="password"
        />
        <Button onClick={login}>Login</Button>
        <Link to="/inscribirse">
          <Button tertiary darken>
            Inscribirse
          </Button>
        </Link>
      </LoginSection>
    </Hero>
  );
};

const Hero = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100vw;
  padding: 200px 0;
  position: relative;
  @media (max-width: 768px) {
    padding: 64px 0;
    justify-content: center;
    text-align: center;
  }
`;

const LogoWrapper = styled.img`
  width: 300px;
`;

const TitleBox = styled.div``;

const LoginSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 768px) {
    margin-top: 24px;
    justify-content: center;
    align-items: center;
  }
`;
