import React from "react";
import styled from "styled-components";
import { text, theme } from "../assets/Variables";

export const Input: React.FC<{
  type?: string;
  value?: string;
  setValue?: Function;
  placeholder?: string;
  autoComplete?: string;
}> = ({
  type = "text",
  value,
  setValue = () => console.warn("Add setValue to input"),
  placeholder = "placeholder",
  autoComplete = "",
}) => {
  return (
    <Element
      type={type}
      value={value}
      onChange={(e) => setValue(e.currentTarget.value)}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
  );
};

const Element = styled.input`
  display: flex;
  width: ${(props) => props.theme.button.width};
  height: ${(props) => props.theme.button.height};
  font-weight: ${text.p.fontWeight};
  font-size: ${text.h4};
  border: none;
  outline: none;
  background-color: white;
  color: black;
  border-radius: ${(props) => props.theme.measurements.radius};
  /* margin: ${(props) => props.theme.measurements.marginS}; */
  padding: 0 1vw;
  @media (max-width: 768px) {
    width: 100%;
    height: initial;
    padding: 8px;
    margin: 0;
  }
  &::placeholder {
    font-weight: ${text.p.fontWeight};
    color: #cfcfcf;
  }
`;
