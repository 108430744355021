export const handleError = (error: any) => {
  console.log("error:", error);
  if (error?.response?.data?.error?.message) {
    if (error?.response?.data?.error?.details?.errors?.length > 1) {
      return error?.response.data.error.details.errors.map(
        (err: { message: string }) => `${err.message}. `
      );
    } else {
      return error?.response?.data?.error?.message;
    }
  } else {
    return "unkown error. Try again at a later time.";
  }
};

export const ToCapitalize = (str: string) => {
  if (str) return str[0].toUpperCase() + str.substring(1);
};
