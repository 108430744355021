import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../assets/Variables";
import { User } from "../../types";

export const Nav: React.FC<{ user: User; setUser: Function }> = ({
  user,
  setUser,
}) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    setUser({
      first: "",
      last: "",
      email: "",
      token: "",
      joined: "",
      emailVerified: false,
      subscribed: false,
    });
    navigate("/");
  };
  return (
    <Wrapper>
      {user.jwt ? (
        <Link to={`/user-home/${user.username}`}>User Page</Link>
      ) : (
        <Link to="/">Home</Link>
      )}
      {user.jwt ? null : ( // <Link to="/courses">Courses</Link>
        <Link to="/inscribirse">Inscribirse</Link>
      )}
      {user.jwt ? (
        <PseudoLink onClick={logout}>Logout</PseudoLink>
      ) : (
        <Link to="/equipo">Equipo</Link>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  z-index: 5;
  position: fixed;
  top: 0;
  right: 0;
  height: ${theme.measurements.paddingL};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${theme.shadow}
  background-color: ${theme.color.primary};
  width: 100%;

  a {
    margin-right: ${theme.measurements.padding};
    padding: calc(${theme.measurements.padding} / 2);
    ${theme.measurements.padding};
    text-decoration: none;
    font-family: montreal;
    font-weight: 600;
    text-transform: capitalize;
    color: ${theme.color.white};

    /* backdrop-filter: blur(0.2vw); */
  }
  a:hover {
    background-color: #ffffff76;
  }
`;

const PseudoLink = styled.div`
  cursor: pointer;
  margin-right: ${theme.measurements.padding};
  padding: calc(${theme.measurements.padding} / 2) ${theme.measurements.padding};
  text-decoration: none;
  font-family: montreal;
  text-transform: capitalize;
  color: ${theme.color.primary};
  background-color: #ffffff;
  :hover {
    background-color: #ffffff76;
  }
`;
