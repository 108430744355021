import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../assets/Variables";
import { Button } from "../components/Button";
import { Text } from "../components/Text";
import { User } from "../types";

export const Course: React.FC<{
  user: User;
  setUser: Function;
  message: null | string;
  setMessage: Function;
}> = ({ user, setUser, message, setMessage }) => {
  const [course, setCourse] = useState<any>(null);
  const [video, setVideo] = useState<any>(null);
  const [selectedVideo, setSelectedVideo] = useState(0);
  const [isSubscribed, setIsSubscribed] = useState(false);
  let { id } = useParams();

  const getCourse = async () => {
    const { data } = await axios({
      method: "GET",
      url: "https://api.pentecostal.pro/api/courses/" + id + "?populate=*",
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
    });
    console.log(data.data);
    setCourse(data.data.attributes);
  };

  const getVideo = async (vId: number) => {
    const { data } = await axios({
      method: "GET",
      url: "https://api.pentecostal.pro/api/videos/" + vId + "?populate=*",
      headers: {
        Authorization: `Bearer ${user.jwt}`,
      },
    });
    console.log("video: ", data.data);
    setVideo(data.data.attributes);
  };

  useEffect(() => {
    console.log("VIDEO CHANGED: ", video);
  }, [video]);

  useEffect(() => {
    if (user.jwt) {
      getCourse();
    }
  }, [user]);

  useEffect(() => {
    console.log("COURSE ISL: ", course);
    if (course?.videos?.data?.length) {
      getVideo(course.videos.data[selectedVideo].id);
      course.users.data.forEach((usr: any) => {
        if (usr.attributes.username === user.username) {
          setIsSubscribed(true);
        }
      });
    }

    console.log("USER AND USERS: ", user.username, course?.users.data);
  }, [course]);

  if (course && video) {
    return (
      <Wrapper>
        <TextContent>
          <Text h1 color="white">
            {course?.title}
          </Text>
          <Text h3 color="white">
            {course?.description}
          </Text>
          {/* <Text>{"Comienza: " + course?.startDate}</Text> */}
        </TextContent>
        <Main>
          <VideoPlayer controls key={video?.file?.data?.attributes?.url}>
            <source
              src={
                "https://api.pentecostal.pro" +
                video?.file?.data?.attributes?.url
              }
            />
          </VideoPlayer>
          <RightSideBar>
            <Text>Videos De Curso</Text>
            <SideVideos>
              {course?.videos?.data?.map((viddy: any, ind: number) => (
                <ViddyOption
                  key={viddy.id}
                  onClick={() => {
                    console.log(viddy.id);
                    setSelectedVideo(ind);
                    getVideo(viddy.id);
                  }}
                  style={{
                    backgroundColor:
                      ind === selectedVideo ? theme.color.secondary : "white",
                  }}
                >
                  <ViddyNumber style={{ color: theme.color.black }}>
                    #{ind + 1}
                  </ViddyNumber>
                  <Text
                    h3
                    color={
                      ind !== selectedVideo
                        ? theme.color.black
                        : theme.color.white
                    }
                  >
                    {viddy.attributes.title}
                  </Text>
                </ViddyOption>
              ))}
            </SideVideos>
          </RightSideBar>
        </Main>

        <BottomSide>
          {/* <Text>Detalles De Curso</Text> */}
          <div>
            <Text h3>Maestro</Text>
            <Text>{course.teacher.data.attributes.name}</Text>
          </div>
          <div>
            <Text h3>Detalles</Text>
            <Text>{course.description}</Text>
          </div>
          <div>
            <Text h3>Comienza</Text>
            <Text>{course.startDate}</Text>
          </div>
          {/* <div>
            <Text h3>Estudiantes</Text>
            <Text>{course.users.data.length}</Text>
          </div> */}
          {/* <Button>{isSubscribed ? "Vete" : "Subscritate"}</Button> */}
        </BottomSide>
      </Wrapper>
    );
  } else {
    return null;
  }
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  /* gap: 2vw; */
`;

const Main = styled.div`
  width: 100vw;
  /* height: 60vw; */
  height: 45vw;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    height: initial;
  }
`;

const SideVideos = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  @media (max-width: 768px) {
    flex-direction: row;
    overflow-y: initial;
    overflow-x: scroll;
    width: 100%;
  }
`;

const TextContent = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${theme.color.primary};
  color: ${theme.color.tertiary};
  padding: 8px;
  padding-top: 100px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

const VideoPlayer = styled.video`
  width: 80vw;
  height: 45vw;
  background-color: aliceblue;
  @media (max-width: 768px) {
    width: 100vw;
    height: 56vw;
  }
`;

const RightSideBar = styled.div`
  width: 20vw;
  height: 45vw;

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vw;
  position: relative;
  background-color: #184b5c;
  p {
    background-color: #2f96b6;
    color: white;
    /* width: 100%; */
    padding: 1vw;
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: initial;
    gap: 0;
    p {
      display: none;
    }
  }
`;

const BottomSide = styled.div`
  width: 100vw;

  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1vw;
  padding: 14px;
  /* overflow-y: scroll; */
  padding-bottom: 100px;
  flex-grow: 1;
  @media (max-width: 768px) {
    height: initial;
  }
`;

const ViddyOption = styled.div`
  /* width: 100%; */
  height: 8vw;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  cursor: pointer;

  scale: 0.9;
  h3 {
    font-size: 1vw !important;
  }
  transition-duration: 0.3s;
  &:hover {
    scale: 0.92;
  }
  @media (max-width: 768px) {
    height: 100px;
    width: 140px;
    align-items: flex-end;
    h3 {
      font-size: 12px !important;
    }
  }
`;

const ViddyNumber = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  font-weight: 700;
  font-size: 24px;
  color: ${theme.color.grey};
  @media (max-width: 768px) {
    font-size: 18px;
    top: 8px;
    left: 8px;
  }
`;
