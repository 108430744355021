import React from "react";
import styled from "styled-components";

export const Position = ({ top, left, bottom, right, children }) => {
  return (
    <ELPosition top={top} left={left} bottom={bottom} right={right}>
      {children}
    </ELPosition>
  );
};

const ELPosition = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
`;
