import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import styled from "styled-components";
import { API, theme } from "../assets/Variables";
import { Text } from "../components/Text";
import { User } from "../types";

export const Paypal: React.FC<{ user: User; setUser: Function }> = ({
  user,
  setUser,
}) => {
  const [showForm, setShowForm] = useState(true);
  const PayBackend = async (cnonToken: string) => {
    setShowForm(false);
    console.log("VALIDY UNTILY: ", user.validUntil);
    try {
      const { data } = await axios({
        method: "POST",
        url: "https://node.pentecostal.pro/square",
        data: {
          cnonToken: cnonToken,
          buyerEmailAddress: user?.email,
          amount: user?.validUntil === null ? 6000 : 4000,
        },
      });

      console.log("resp: ", data.data);
      if (data.data.payment.status === "COMPLETED") {
        //update user
        const validUntil = moment().add(1, "month").format("yyyy-MM-DD");
        console.log(validUntil);
        const resp = await axios({
          method: "PUT",
          url: "https://api.pentecostal.pro/api/users/" + user?.id,
          data: {
            validUntil: validUntil,
          },
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        });

        console.log("REEEEEEESP: ", resp);
        setUser((prev: any) => {
          return { ...resp.data, jwt: prev.jwt };
        });
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <Wrapper>
      <Text h1>Checkout</Text>
      <Text h3>
        {user.validUntil === null
          ? "Bienvenidos. El total es $60 para el primer mes. ($20 por registraccion, y $40 por el mes)"
          : "Se ha expirado su cuenta :( . Pague $40 para obtener otro mes."}
      </Text>
      <Text h2>{user.validUntil === null ? "$60" : "$40"}</Text>
      {showForm ? (
        <MyPaymentForm PayBackend={PayBackend} />
      ) : (
        <Text h2>Processing. Please wait...</Text>
      )}
    </Wrapper>
  );
};

const MyPaymentForm: React.FC<{ PayBackend: Function }> = ({ PayBackend }) => (
  <PaymentForm
    /**
     * Identifies the calling form with a verified application ID generated from
     * the Square Application Dashboard.
     */
    applicationId="sq0idp-eHDR0LJ2Zuwr4JDNqvDgnA"
    /**
     * Invoked when payment form receives the result of a tokenize generation
     * request. The result will be a valid credit card or wallet token, or an error.
     */
    cardTokenizeResponseReceived={(token, buyer) => {
      console.info({ token, buyer });
      if (token.status === "OK") {
        PayBackend(token.token);
      }
    }}
    /**
     * This function enable the Strong Customer Authentication (SCA) flow
     *
     * We strongly recommend use this function to verify the buyer and reduce
     * the chance of fraudulent transactions.
     */

    /**
     * Identifies the location of the merchant that is taking the payment.
     * Obtained from the Square Application Dashboard - Locations tab.
     */
    locationId="LF9AN3XFV4NQD"
  >
    <CreditCard />
  </PaymentForm>
);

const Wrapper = styled.div`
  padding: 16px;

  padding-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  background-color: ${theme.color.tertiary};
  min-height: 100vh;

  form {
    width: 100%;
  }
`;

export default MyPaymentForm;
