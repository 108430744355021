import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../assets/Variables";

export const Message: React.FC<{
  message: null | string;
  setMessage: Function;
}> = ({ message, setMessage }) => {
  const [display, setDisplay] = useState(false);

  const wrapperRef = useRef(null);
  const textRef = useRef(null);

  const toggle = () => {
    const tl = gsap.timeline();
    if (display) {
      tl.to(wrapperRef.current, {
        opacity: 1,
        scale: 1,
        duration: 0.5,
        ease: "ease",
      })
        .to(textRef.current, {
          opacity: 1,
          duration: 0.7,
          x: 0,
          ease: "ease",
        })
        .to(wrapperRef.current, {
          delay: 3,
          opacity: 0,
          duration: 0.3,
          ease: "ease",
          onComplete: () => setMessage(null),
        });
    }
  };

  useEffect(() => {
    message ? setDisplay(true) : setDisplay(false);
  }, [message]);

  useEffect(() => {
    toggle();
  }, [display]);

  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        <Text ref={textRef}>{message}</Text>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  transform: scale(0);
  pointer-events: none;
  opacity: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 25vw;
  background-color: ${theme.color.primary};
  /* min-height: 15vw; */
  padding: 3vw;
  border-radius: ${theme.measurements.radius};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h1`
  opacity: 0;
  transform: translateX(-100px);
  color: ${theme.color.secondary};
`;
