import axios from "axios";
import { resolvePtr } from "dns";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { API, theme } from "../assets/Variables";
import { Button } from "../components/Button";
import { Input } from "../components/Input";
import { Text } from "../components/Text";
import { MessageInterface, User } from "../types";
import { handleError } from "../utils";

export const Register: React.FC<{
  user: User;
  setUser: Function;
  message: null | string;
  setMessage: Function;
}> = ({ user, setUser, message, setMessage }) => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const register = async () => {
    try {
      const resp = await axios({
        method: "POST",
        url: "https://api.pentecostal.pro/api/auth/local/register",
        data: {
          username: username,
          firstname: first,
          lastname: last,
          email: email,
          password: password,
        },
      });
      if (resp?.statusText === "OK") {
        console.log("register success: ", resp);
        setMessage(
          "An email has been sent with a link to activate your account."
        );
        setUser({ ...user, ...resp.data.user });
      } else {
        setMessage("That email is already in use.");
      }
    } catch (error: any) {
      setMessage(handleError(error));
    }
  };

  useEffect(() => {}, []);

  return (
    <Wrapper>
      <Text h1 color={theme.color.white}>
        Inscribirse
      </Text>
      <Form>
        <Input
          placeholder="Nombre"
          value={first}
          setValue={setFirst}
          type={"firstname"}
          autoComplete="given-name"
        />
        <Input
          placeholder="Apeido"
          value={last}
          setValue={setLast}
          type={"lastname"}
        />
        <Input placeholder="Nickname" value={username} setValue={setUsername} />
        <Input
          placeholder="Email"
          value={email}
          setValue={setEmail}
          type="email"
        />
        <Input
          placeholder="Password"
          value={password}
          setValue={setPassword}
          // type="password"
        />
        <Button onClick={register}>Register</Button>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Form = styled.section`
  margin-top: ${theme.measurements.paddingL};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;
