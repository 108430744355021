import styled from "styled-components";
import { HomeHero } from "../sections/home/Hero";
import { InfoHome } from "../sections/home/Info";
import { User } from "../types";

export const Home: React.FC<{
  user: User;
  setUser: Function;
  message: null | string;
  setMessage: Function;
}> = ({ user, setUser, message, setMessage }) => {
  return (
    <Wrapper>
      <HomeHero setMessage={setMessage} setUser={setUser} />
      <InfoHome />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 64px;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
